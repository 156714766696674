/* square buttons */

@font-face {
    font-family: 'Rubik';
    src:         local('Rubik'),
                 url('../fonts/Rubik-VariableFont_wght.ttf') format('truetype');
}

.rec {
    //background-color: green !important;
}

.rec.rec-arrow {
    color:            #FFFFFF !important;
    background-color: #704A98 !important;
}

//.rec.rec-arrow-right {
//    //color: red !important;
//}

/* round buttons on hover */
.rec.rec-arrow:hover {
    color:            #FFFFFF !important;
    background-color: #622993 !important;
}

.rec.rec-arrow:disabled {
    color:            #CCCCCC !important;
    background-color: #E6E6E6 !important;
}

.rec.rec-dot {
    width:            14px;
    height:           14px;
    transition:       all 0.3s;
    border:           1px solid #622993;
    background-color: #704A98 !important;
    box-shadow:       none;
}

.rec.rec-dot:hover {
    //transform:        scale(1.2);
    background-color: #622993 !important;
    box-shadow:       none;
}

.rec.rec-dot.rec-dot_active {
    transform:        scale(1.2);
    background-color: #622993 !important;
    box-shadow:       none;
}

/* disable default outline on focused items */
/* add custom outline on focused items */
//.rec-carousel-item:focus {
//    outline:          none;
//    background-color: red !important;
//    //box-shadow: inset 0 0 1px 1px lightgrey;
//}

//remove unnecessary padding in the faq answer container
.ant-collapse-content-box {
    padding: 0 !important;
}

// change color and font size to faq question
.ant-collapse-header {
    font-size: 18px !important;
    color:     #6E6E6E !important;
}

//.ant-card-meta-title {
//    color: #8D99AE !important;
//}
//
//.ant-card-meta-description {
//    color: #6E6E6E !important;
//}